<template>
  <div>
    <h1>搜索结果</h1>
    <ul>
      <li v-for="article in searchResults" :key="article.id" :style="{ backgroundColor: article.backgroundColor }">
        <div v-if="isAdmin" class="admin-buttons">
          <button @click="deleteArticle(article.id)" :class="getButtonClass(article.backgroundColor)">删除文章</button>
          <button @click="changeBackgroundColor(article.id, '#ADD8E6')" :class="getButtonClass(article.backgroundColor)">已使用</button>
          <button @click="changeBackgroundColor(article.id, '#FFC0CB')" :class="getButtonClass(article.backgroundColor)">不通过</button>
          <button @click="changeBackgroundColor(article.id, '')" :class="getButtonClass(article.backgroundColor)">恢复默认</button>
        </div>
        <div class="article-header">
          <h4>{{ article.author }}</h4>
          <span class="article-time">{{ formatDate(article.timestamp) }}</span>
        </div>
        <p class="article-content"> {{ article.content }}</p>
        <div v-if="userId" class="comment-section">
          <div class="comment-header">
            <h4>评论</h4>
            <button @click="toggleCommentForm(article.id)" :class="getButtonClass(article.backgroundColor)">发表评论</button>
          </div>
          <form v-if="showCommentForm[article.id]" @submit.prevent="addComment(article.id)">
            <div>
              <label for="comment">评论:</label>
              <textarea id="comment" v-model="newComment" required></textarea>
            </div>
            <button type="submit" :class="getButtonClass(article.backgroundColor)">提交评论</button>
          </form>
        </div>
        <ul>
          <li v-for="comment in article.comments" :key="comment.timestamp">
            <div class="comment-header">
              <h4>{{ comment.username }}</h4>
              <span class="comment-time">{{ formatDate(comment.timestamp) }}</span>
            </div>
            <button v-if="comment.userId === userId || isAdmin" @click="deleteComment(article.id, comment.timestamp)" :class="getButtonClass(article.backgroundColor)">删除</button>
            <p class="comment-content">{{ comment.content }}</p>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';

export default {
  data() {
    return {
      userId: parseInt(localStorage.getItem('userId')), // 确保 userId 是整数
      isAdmin: localStorage.getItem('isAdmin') === 'true', // 检查是否是管理员
      newComment: '',
      showCommentForm: {} // 用于控制评论表单的显示状态
    };
  },
  computed: {
    ...mapState(['searchResults'])
  },
  watch: {
    '$route.query.q': {
      immediate: true,
      handler(newQuery) {
        if (newQuery) {
          this.performSearch(newQuery);
        }
      }
    }
  },
  methods: {
  ...mapActions(['setSearchResults']), // 使用 Vuex action
  async performSearch(query) {
    try {
      // 调用 /articles/search API 获取搜索结果
      const response = await axios.get(`https://nyanya.fun/api/articles/search?q=${query}`);
      const searchResults = response.data
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)); // 按时间降序排序
      this.setSearchResults(searchResults);
    } catch (error) {
      console.error('There was an error searching articles!', error);
    }
  },
  formatDate(date) {
    return new Date(date).toLocaleString(); // 显示完整的日期和时间
  },
  toggleCommentForm(articleId) {
    this.showCommentForm = {
      ...this.showCommentForm,
      [articleId]: !this.showCommentForm[articleId]
    };
  },
  async addComment(articleId) {
    try {
      await axios.post(`https://nyanya.fun/api/articles/${articleId}/comments`, {
        userId: this.userId, // 确保 userId 是整数
        content: this.newComment
      });
      this.newComment = '';
      this.showCommentForm = {
        ...this.showCommentForm,
        [articleId]: false
      };
      this.performSearch(this.$route.query.q);
    } catch (error) {
      console.error('There was an error adding the comment!', error);
    }
  },
  async deleteComment(articleId, commentTimestamp) {
    try {
      await axios.delete(`https://nyanya.fun/api/articles/${articleId}/comments/${commentTimestamp}`);
      this.performSearch(this.$route.query.q);
    } catch (error) {
      console.error('There was an error deleting the comment!', error);
    }
  },
  async deleteArticle(articleId) {
    try {
      await axios.delete(`https://nyanya.fun/api/articles/${articleId}?isAdmin=${this.isAdmin}`);
      this.performSearch(this.$route.query.q);
    } catch (error) {
      console.error('There was an error deleting the article!', error);
    }
  },
  async changeBackgroundColor(articleId, backgroundColor) {
    try {
      await axios.put(`https://nyanya.fun/api/articles/${articleId}/background?isAdmin=${this.isAdmin}`, {
        backgroundColor
      });
      this.performSearch(this.$route.query.q);
    } catch (error) {
      console.error('There was an error changing the background color!', error);
    }
  },
  getButtonClass(backgroundColor) {
    if (backgroundColor === '#ADD8E6') {
      return 'blue-button';
    } else if (backgroundColor === '#FFC0CB') {
      return 'red-button';
    } else {
      return 'green-button';
    }
  }
}
};
</script>

<style>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
li:hover {
  background-color: #f0f0f0;
}
textarea {
  width: 100%;
  height: 100px;
  resize: vertical;
}

/* 保留换行符 */
.article-content, .comment-content {
  white-space: pre-wrap; /* 保留换行符并允许文字换行 */
}
button {
  margin-left: 10px;
}

/* 管理员按钮样式 */
.admin-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

/* 默认绿色按钮 */
.green-button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #77dd99;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.green-button:hover {
  background-color: #89e9a9;
}

/* 淡蓝背景下的蓝色按钮 */
.blue-button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #87CEFA;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blue-button:hover {
  background-color: #ADD8E6;
}

/* 淡红背景下的红色按钮 */
.red-button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #FF6347;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.red-button:hover {
  background-color: #FFC0CB;
}

/* 文章头部样式 */
.article-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* 评论头部样式 */
.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* 评论删除按钮样式 */
.comment-delete-button {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* 评论区域样式 */
.comment-section {
  margin-top: 20px;
}
</style>