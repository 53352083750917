import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/Home.vue';
import UserRegister from '../components/UserRegister.vue';
import UserLogin from '../components/Login.vue'; // 修改导入路径
import ArticleList from '../components/ArticleList.vue';
import SearchResults from '../views/SearchResults.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/register',
    name: 'Register',
    component: UserRegister
  },
  {
    path: '/login',
    name: 'Login',
    component: UserLogin // 修改组件名称
  },
  {
    path: '/articles',
    name: 'Articles',
    component: ArticleList
  },
  {
    path: '/search',
    name: 'SearchResults',
    component: SearchResults
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;