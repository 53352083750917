<template>
  <div>
    <h2>千羽社投稿收录网站</h2>
    <br>
    <div class="ts">
      <h3 class="blue">蓝色块为已收入视频</h3>
    </div>
    <div class="ts">
      <h3 class="red">红色块为审核不通过</h3>
    </div>
    <br>
    <h3>登录后可在稿件下发表评论</h3>
    <br>
    <h3>链接跳转</h3>
    <div class="link-container">
      <div class="link-item">
        <a href="https://space.bilibili.com/3546557418834456" target="_blank">bilibili</a>
      </div>
      <div class="link-item">
        <a href="http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=ntol9hKVP8uwXELY-AaYGb25NzExIyiU&authKey=ySkRj3cNdQ1xfuOuBJHgQnf9uZZ%2BZ78j%2Fdd5aya8X1xRsZvwdCxvli3ZBEj5YxfX&noverify=0&group_code=927390333" target="_blank">QQ群</a>
      </div>
    </div>
    <br>
    <br>
  </div>
</template>

<script>
export default {
  name: 'HomePage', 
};
</script>

<style scoped>
h2, h3 {
  color: #333;
  text-align: center; /* 使标题居中 */
}

p {
  color: #666;
}

a {
  font-size: large;
  text-decoration: none;
  color: #333;
  display: block; /* 使链接块级显示 */
  margin: 10px 0; /* 调整上下间距 */
}

a:hover {
  color: #77dd99;
}

.ts {
  display: flex;
  justify-content: center;
  width: 100%; /* 使容器占满整个页面宽度 */
  margin: 0 auto; /* 使容器居中 */
}

.blue {
  background-color: #ADD8E6;
  padding: 10px;
  border-radius: 10px; /* 添加圆角 */
  text-align: center; /* 使文本居中 */
}

.red {
  background-color: #FFC0CB;
  padding: 10px;
  border-radius: 10px; /* 添加圆角 */
  text-align: center; /* 使文本居中 */
}

.link-container {
  display: flex;
  flex-direction: column; /* 使链接垂直排列 */
  align-items: center; /* 使链接居中对齐 */
  margin: 20px 0; /* 调整上下间距 */
}

.link-item {
  text-align: center; /* 使链接文本居中 */
}
</style>