<template>
  <div>
    <h1>用户登录</h1>
    <form @submit.prevent="login">
      <div class="form-group">
        <label for="username">用户名：</label>
        <input type="text" id="username" v-model="username" required />
      </div>
      <div class="form-group">
        <label for="password">密码：</label>
        <input type="password" id="password" v-model="password" required />
      </div>
      <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
      <button type="submit">登录</button>
      <button @click="goToRegister">注册</button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';

export default {
  name: 'UserLogin',
  data() {
    return {
      username: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    ...mapActions(['loginUser']), // 使用 Vuex action
    async login() {
      try {
        const response = await axios.post('https://nyanya.fun/api/login', {
          username: this.username,
          password: this.password
        });
        if (response.status === 200) {
          const { userId, isAdmin, username } = response.data;
          this.loginUser({ userId, username, isAdmin });
          this.$router.push('/articles');
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = '登录失败，请稍后再试';
        }
      }
    },
    goToRegister() {
      this.$router.push('/register');
    }
  }
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
}
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
label {
  flex: 1;
  text-align: right;
  margin-right: 10px;
}
input {
  flex: 2;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
button {
  padding: 10px;
  background-color: #77dd99;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
}
button:hover {
  background-color: #89e9a9;
}
.error {
  color: red;
  margin: 10px 0;
}
</style>