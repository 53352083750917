<template>
  <div>
    <h1>稿件目录</h1>
    <div v-if="!selectedYear && !selectedMonth && !selectedDate">
      <ul>
        <li v-for="(year, index) in years" :key="index" @click="selectYear(year)">
          {{ year }}
        </li>
      </ul>
    </div>
    <div v-else-if="selectedYear && !selectedMonth && !selectedDate">
      <button @click="clearSelection" :class="getButtonClass(null)">返回年份列表</button>
      <h2>{{ selectedYear }} 年</h2>
      <ul>
        <li v-for="(month, index) in months" :key="index" @click="selectMonth(month)">
          {{ month }}
        </li>
      </ul>
    </div>
    <div v-else-if="selectedYear && selectedMonth && !selectedDate">
      <button @click="clearSelection" :class="getButtonClass(null)">返回年份列表</button>
      <button @click="clearMonth" :class="getButtonClass(null)">返回月份列表</button>
      <h2>{{ selectedYear }} 年 {{ selectedMonth }} </h2>
      <ul>
        <li v-for="(date, index) in dates" :key="index" @click="selectDate(date)">
          {{ date }}
        </li>
      </ul>
    </div>
    <div v-else>
      <button @click="clearSelection" :class="getButtonClass(null)">返回年份列表</button>
      <button @click="clearMonth" :class="getButtonClass(null)">返回月份列表</button>
      <button @click="clearDate" :class="getButtonClass(null)">返回日期列表</button>
      <h2>{{ selectedDate }}</h2>
      <ul>
        <li v-for="article in filteredArticles" :key="article.id" :style="{ backgroundColor: article.backgroundColor }">
          <div v-if="isAdmin" class="admin-buttons">
            <button @click="deleteArticle(article.id)" :class="getButtonClass(article.backgroundColor)">删除</button>
            <button @click="changeBackgroundColor(article.id, '#ADD8E6')" :class="getButtonClass(article.backgroundColor)">已使用</button>
            <button @click="changeBackgroundColor(article.id, '#FFC0CB')" :class="getButtonClass(article.backgroundColor)">不通过</button>
            <button @click="changeBackgroundColor(article.id, '')" :class="getButtonClass(article.backgroundColor)">恢复默认</button>
          </div>
          <div class="article-header">
            <h4>{{ article.author }}</h4>
            <span class="article-time">{{ formatDate(article.timestamp) }}</span>
          </div>
          <p class="article-content"> {{ article.content }}</p>
          <div v-if="userId" class="comment-section">
            <div class="comment-header">
              <h4>评论</h4>
              <button @click="toggleCommentForm(article.id)" :class="getButtonClass(article.backgroundColor)">发表评论</button>
            </div>
            <form v-if="showCommentForm[article.id]" @submit.prevent="addComment(article.id)">
              <div>
                <label for="comment">评论:</label>
                <textarea id="comment" v-model="newComment" required></textarea>
              </div>
              <button type="submit" :class="getButtonClass(article.backgroundColor)">提交评论</button>
            </form>
          </div>
          <ul>
            <li v-for="comment in article.comments" :key="comment.timestamp">
              <div class="comment-header">
                <h4>{{ comment.username }}</h4>
                <span class="comment-time">{{ formatDate(comment.timestamp) }}</span>
              </div>
              <button v-if="comment.userId === userId || isAdmin" @click="deleteComment(article.id, comment.timestamp)" :class="getButtonClass(article.backgroundColor)">删除</button>
              <p class="comment-content">{{ comment.content }}</p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      articles: [],
      selectedYear: null,
      selectedMonth: null,
      selectedDate: null,
      userId: parseInt(localStorage.getItem('userId')), // 确保 userId 是整数
      isAdmin: localStorage.getItem('isAdmin') === 'true', // 检查是否是管理员
      newComment: '',
      showCommentForm: {} // 用于控制评论表单的显示状态
    };
  },
  created() {
    this.fetchArticles();
  },
  computed: {
    years() {
      const years = new Set(this.articles.map(article => new Date(article.timestamp).getFullYear()));
      return Array.from(years).sort((a, b) => b - a);
    },
    months() {
      if (!this.selectedYear) return [];
      const months = new Set(this.articles
        .filter(article => new Date(article.timestamp).getFullYear() === this.selectedYear)
        .map(article => new Date(article.timestamp).toLocaleString('default', { month: 'long' }))
      );
      return Array.from(months).sort((a, b) => new Date(`${a} 1`) - new Date(`${b} 1`));
    },
    dates() {
      if (!this.selectedYear || !this.selectedMonth) return [];
      const dates = new Set(this.articles
        .filter(article => new Date(article.timestamp).getFullYear() === this.selectedYear)
        .filter(article => new Date(article.timestamp).toLocaleString('default', { month: 'long' }) === this.selectedMonth)
        .map(article => new Date(article.timestamp).toLocaleString('default', { day: '2-digit', month: 'long', year: 'numeric' }))
      );
      return Array.from(dates).sort((a, b) => new Date(a) - new Date(b));
    },
    filteredArticles() {
      if (!this.selectedYear || !this.selectedMonth || !this.selectedDate) return [];
      return this.articles.filter(article => {
        const articleDate = new Date(article.timestamp).toLocaleString('default', { day: '2-digit', month: 'long', year: 'numeric' });
        return articleDate === this.selectedDate;
      });
    }
  },
  methods: {
    async fetchArticles() {
      try {
        const response = await axios.get('https://nyanya.fun/api/articles');
        this.articles = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      } catch (error) {
        console.error('There was an error fetching the articles!', error);
      }
    },
    selectYear(year) {
      this.selectedYear = year;
      this.selectedMonth = null;
      this.selectedDate = null;
    },
    selectMonth(month) {
      this.selectedMonth = month;
      this.selectedDate = null;
    },
    selectDate(date) {
      this.selectedDate = date;
    },
    clearSelection() {
      this.selectedYear = null;
      this.selectedMonth = null;
      this.selectedDate = null;
    },
    clearMonth() {
      this.selectedMonth = null;
      this.selectedDate = null;
    },
    clearDate() {
      this.selectedDate = null;
    },
    formatDate(date) {
      return new Date(date).toLocaleString(); // 显示完整的日期和时间
    },
    toggleCommentForm(articleId) {
      this.showCommentForm = {
        ...this.showCommentForm,
        [articleId]: !this.showCommentForm[articleId]
      };
    },
    async addComment(articleId) {
      try {
        await axios.post(`https://nyanya.fun/api/articles/${articleId}/comments`, {
          userId: this.userId, // 确保 userId 是整数
          content: this.newComment
        });
        this.newComment = '';
        this.showCommentForm = {
          ...this.showCommentForm,
          [articleId]: false
        };
        this.fetchArticles();
      } catch (error) {
        console.error('There was an error adding the comment!', error);
      }
    },
    async deleteComment(articleId, commentTimestamp) {
      try {
        await axios.delete(`https://nyanya.fun/api/articles/${articleId}/comments/${commentTimestamp}`);
        this.fetchArticles();
      } catch (error) {
        console.error('There was an error deleting the comment!', error);
      }
    },
    async deleteArticle(articleId) {
      try {
        await axios.delete(`https://nyanya.fun/api/articles/${articleId}?isAdmin=${this.isAdmin}`);
        this.fetchArticles();
      } catch (error) {
        console.error('There was an error deleting the article!', error);
      }
    },
    async changeBackgroundColor(articleId, backgroundColor) {
      try {
        await axios.put(`https://nyanya.fun/api/articles/${articleId}/background?isAdmin=${this.isAdmin}`, {
          backgroundColor
        });
        this.fetchArticles();
      } catch (error) {
        console.error('There was an error changing the background color!', error);
      }
    },
    getButtonClass(backgroundColor) {
      if (backgroundColor === '#ADD8E6') {
        return 'blue-button';
      } else if (backgroundColor === '#FFC0CB') {
        return 'red-button';
      } else {
        return 'green-button';
      }
    }
  }
};
</script>

<style>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
li:hover {
  background-color: #f0f0f0;
}
textarea {
  width: 100%;
  height: 100px;
  resize: vertical;
}

/* 保留换行符 */
.article-content, .comment-content {
  white-space: pre-wrap; /* 保留换行符并允许文字换行 */
}
button {
  margin-left: 10px;
}

/* 返回列表按钮样式 */
.back-button {
  padding: 5px 10px; /* 减小内边距 */
  font-size: 14px; /* 与文字大小匹配 */
  background-color: #77dd99;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #89e9a9;
}

/* 管理员按钮样式 */
.admin-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

/* 默认绿色按钮 */
.green-button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #77dd99;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.green-button:hover {
  background-color: #89e9a9;
}

/* 淡蓝背景下的蓝色按钮 */
.blue-button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #87CEFA;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blue-button:hover {
  background-color: #ADD8E6;
}

/* 淡红背景下的红色按钮 */
.red-button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #FF6347;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.red-button:hover {
  background-color: #FFC0CB;
}

/* 文章头部样式 */
.article-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* 评论头部样式 */
.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* 评论删除按钮样式 */
.comment-delete-button {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* 评论区域样式 */
.comment-section {
  margin-top: 20px;
}
</style>