import { createStore } from 'vuex';

export default createStore({
  state: {
    searchResults: [],
    isLoggedIn: localStorage.getItem('userId') !== null,
    username: localStorage.getItem('username'),
    isAdmin: localStorage.getItem('isAdmin') === 'true'
  },
  mutations: {
    setSearchResults(state, results) {
      state.searchResults = results;
    },
    setLoggedIn(state, status) {
      state.isLoggedIn = status;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    }
  },
  actions: {
    setSearchResults({ commit }, results) {
      commit('setSearchResults', results);
    },
    loginUser({ commit }, { userId, username, isAdmin }) {
      localStorage.setItem('userId', userId);
      localStorage.setItem('username', username);
      localStorage.setItem('isAdmin', isAdmin);
      commit('setLoggedIn', true);
      commit('setUsername', username);
      commit('setIsAdmin', isAdmin);
    },
    logoutUser({ commit }) {
      localStorage.removeItem('userId');
      localStorage.removeItem('username');
      localStorage.removeItem('isAdmin');
      commit('setLoggedIn', false);
      commit('setUsername', '');
      commit('setIsAdmin', false);
    }
  },
  getters: {
    searchResults: state => state.searchResults,
    isLoggedIn: state => state.isLoggedIn,
    username: state => state.username,
    isAdmin: state => state.isAdmin
  }
});