<template>
  <div id="app">
    <NavBar></NavBar>
    <main>
      <router-view></router-view>
    </main>
    <footer>
      <a href="https://beian.miit.gov.cn/" target="_blank">吉ICP备2024020175号-1</a>
    </footer>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'; // 使用新的组件名称

export default {
  name: 'App',
  components: {
    NavBar
  },
  data() {
    return {
      // 全局数据
    };
  },
  methods: {
    // 全局方法
  },
  created() {
    // 组件创建时的生命周期钩子
  },
  mounted() {
    // 组件挂载后的生命周期钩子
  }
};
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin-top: 60px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  padding: 20px;
  flex: 1;
}

footer {
  text-align: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-top: 1px solid #e7e7e7;
  margin-left: -10px;
  margin-right: -10px;
}

a{
  text-decoration: none;
  color: #333;
}

a:hover {
  color: #77dd99;
}

</style>