<template>
  <div>
    <h1>用户注册</h1>
    <form @submit.prevent="register">
      <div class="form-group">
        <label for="username">用户名： </label>
        <input type="text" id="username" v-model="username" required />
      </div>
      <div class="form-group">
        <label for="password">密码： </label>
        <input type="password" id="password" v-model="password" required />
      </div>
      <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
      <button type="submit">注册</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserRegister',
  data() {
    return {
      username: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    async register() {
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
      if (!passwordRegex.test(this.password)) {
        this.errorMessage = '密码必须包含字母和数字，且最少为8位';
        return;
      }

      try {
        const response = await axios.post('https://nyanya.fun/api/register', {
          username: this.username,
          password: this.password
        });
        if (response.status === 201) {
          this.$router.push('/login');
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = '注册失败，请稍后再试';
        }
      }
    }
  }
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
}
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
label {
  flex: 1;
  text-align: right;
  margin-right: 10px;
}
input {
  flex: 2;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
button {
  padding: 10px;
  background-color: #77dd99;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}
button:hover {
  background-color: #89e9a9;
}
.error {
  color: red;
  margin: 10px 0;
}
</style>