<template>
  <nav>
    <div class="left-section">
      <div class="logo">
        <router-link to="/">千羽社</router-link>
      </div>
      <div class="links">
        <router-link to="/articles"><img src="../assets/list.png" alt="list"></router-link>
      </div>
    </div>
    <div class="right-section">
      <div class="actions">
        <div v-if="!isLoggedIn">
          <router-link to="/login"><img src="../assets/user.png" alt="user"></router-link>
        </div>
        <div v-else class="user-action">
          <span>{{ username }}</span>
          <button @click="logout"><img src="../assets/exit.png" alt="exit"></button>
        </div>
      </div>
      <div class="search">
        <form @submit.prevent="performSearch">
          <input type="text" v-model="searchQuery" placeholder="搜索稿件或作者" />
          <button type="submit"><img src="../assets/search.png" alt="search"></button>
        </form>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      searchQuery: ''
    };
  },
  computed: {
    ...mapState(['isLoggedIn', 'username'])
  },
 methods: {
  ...mapActions(['setSearchResults', 'logoutUser']), // 使用 Vuex action
  performSearch() {
    if (this.searchQuery.trim() !== '') {
      this.$router.push({ name: 'SearchResults', query: { q: this.searchQuery } });
    }
    },
    logout() {
      this.logoutUser();
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
nav {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  background-color: #77dd99;
  padding: 10px 20px;
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  margin-left: -8px;
}

.left-section,
.right-section {
  display: flex;
  align-items: center;
}

.logo a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  margin-right: 20px;
}

.links a {
  color: white;
  text-decoration: none;
  display: flex; /* 确保链接使用 Flexbox */
  align-items: center; /* 确保链接内的内容垂直居中 */
  margin: 0 10px;
  transition: background-color 0.3s;
  padding: 5px;
}

.links a:hover {
  background-color: #89e9a9;
  border-radius: 5px;
}

.actions {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.actions a {
  color: white;
  text-decoration: none;
  display: flex; /* 确保链接使用 Flexbox */
  align-items: center; /* 确保链接内的内容垂直居中 */
  margin: 0 10px;
  transition: background-color 0.3s;
  padding: 5px; /* 给链接添加内边距 */
}

.actions a:hover {
  background-color: #89e9a9;
  border-radius: 5px;
}

.user-action {
  display: flex;
  align-items: center;
}

.user-action span {
  margin-right: 10px;
}

.user-action button {
  padding: 5px 10px;
  background-color: #77dd99;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 34px; /* 与输入框高度一致 */
  width: 34px; /* 与输入框高度一致 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-action button:hover {
  background-color: #89e9a9;
}

.user-action button img {
  height: 20px;
  margin: 0; /* 移除图片的额外间距 */
}

.search form {
  display: flex;
  align-items: center;
  max-width: 150px; /* 调整搜索框的最大宽度 */
  position: relative;
  flex: 1;
}

.search input {
  padding: 5px 35px 5px 5px; /* 保留右侧空间给按钮 */
  border: 1px solid #ccc;
  border-radius: 5px;
  flex-grow: 1;
  width: 100%; /* 确保输入框占满整个表单的宽度 */
}

.search button {
  position: absolute;
  right: 5px; /* 距离输入框右侧的距离 */
  top: 50%;
  transform: translateY(-50%);
  padding: 5px 10px;
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 34px; /* 与输入框高度一致 */
  width: 34px; /* 与输入框高度一致 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.search button img {
  height: 20px;
  margin: 0; /* 移除图片的额外间距 */
}

/* 媒体查询：屏幕宽度小于600px时 */
@media (max-width: 600px) {
  .search form {
    max-width: 150px; /* 保持搜索框宽度不变 */
  }

  .actions span {
    display: none;
  }

  .actions a,
  .links a {
    margin: 0 5px;
  }
}

img {
  height: 20px;
  margin-top: 0; /* 移除顶部额外的间距 */
  margin-right: 5px; /* 如果需要，可以增加右侧间距 */
}
</style>